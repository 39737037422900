import { cache } from "react";
import { rawExecuteQuery } from "@datocms/cda-client";
import { createHash } from "crypto";

import vars from "@/config/vars";

import { storeQueryCacheTags } from "./database";
import { parseXCacheTagsResponseHeader } from "./cache-tags";

// // Define types for request parameters and response data
interface RequestParams {
  query: string;
  variables?: Record<string, any>;
  includeDrafts?: boolean;
  excludeInvalid?: boolean;
  // revalidate?: number | undefined;
}

// interface ResponseData {
//   data: any;
//   errors: {
//     message: string;
//   }[];
// }

// Define type for the cache function
// type FetchFunction = (serializedInit: string) => Promise<ResponseData>;

// const BASE_URL = "https://graphql.datocms.com/";
// let BASE_URL: string;

// const dedupedFetch: FetchFunction = cache(async (serializedInit) => {
//   const response = await fetch(BASE_URL, JSON.parse(serializedInit));
//   const responseBody = await response.json();
//   if (!response.ok) {
//     throw new Error(`${response.status} ${response.statusText}: ${JSON.stringify(responseBody)}`);
//   }
//   return responseBody;
// });

// export async function performRequest({
//   query,
//   variables = {},
//   includeDrafts = false,
//   excludeInvalid = false,
//   revalidate = undefined,
// }: RequestParams): Promise<any> {
//   try {
//     //base usl according to the draft condition
//     BASE_URL = includeDrafts
//       ? "https://graphql.datocms.com/preview"
//       : "https://graphql.datocms.com";
//     const result = await dedupedFetch(
//       JSON.stringify({
//         method: "POST",
//         headers: {
//           Authorization: `Bearer ${vars.DatoApiToken}`,
//           ...(includeDrafts ? { "X-Include-Drafts": "true" } : {}),
//           ...(excludeInvalid ? { "X-Exclude-Invalid": "true" } : {}),
//           ...(vars.DatoEnv ? { "X-Environment": vars.DatoEnv } : {}),
//         },
//         body: JSON.stringify({ query, variables, revalidate }),
//         next: { revalidate },
//       }),
//     );
//     if (result.data) return result.data;
//     else if (result.errors?.length) {
//       // throw the first error
//       const errorObj = result.errors?.[0];
//       let message = "Internal Server Error";

//       // eslint-disable-next-line no-console
//       console.log(JSON.stringify(result.errors));

//       if (errorObj?.message) message = errorObj.message;
//       throw new Error(message);
//     }
//   } catch (error) {
//     throw error;
//   }
// }

export const executeQueryWithoutMemoization = async ({
  query,
  variables = {},
  includeDrafts = false,
  excludeInvalid = false,
}: RequestParams): Promise<any> => {
  const queryId = generateQueryId(query, variables);

  const [data, response] = await rawExecuteQuery(query, {
    token: vars.DatoApiToken!,
    excludeInvalid,
    returnCacheTags: true,
    includeDrafts,
    environment: vars.DatoEnv,
    variables,
    requestInitOptions: {
      cache: "force-cache",
      next: {
        tags: [queryId],
      },
    },
  });

  /**
   * Converts the cache tags string from the headers into an array of CacheTag
   * type.
   */
  const cacheTags = parseXCacheTagsResponseHeader(response.headers.get("x-cache-tags"));

  await storeQueryCacheTags(queryId, cacheTags);

  return data;
};

/*
 * Generates a unique identifier for a GraphQL query by building a SHA1 hash
 * of the query itself and its variables.
 */
function generateQueryId<Variables = Record<string, unknown>>(
  query: string,
  variables?: Variables,
) {
  return createHash("sha1")
    .update(query)
    .update(JSON.stringify(variables) || "")
    .digest("hex");
}

/*
 * Next.js extends the `fetch` API by memorizing identical requests that happen
 * multiple times. This guarantees that the same `fetch()` call in a React
 * component tree is only carried out once per server request.
 *
 * We want the same benefit for our `executeQuery` function, and to achieve
 * this, we utilize React's `cache()` function.
 *
 * For more information on Request Memoization in Next.js, visit:
 * https://nextjs.org/docs/app/building-your-application/caching#request-memoization
 *
 * For more information on React's `cache`, visit:
 * https://react.dev/reference/react/cache
 */
export const performRequest = cacheWithDeepCompare(executeQueryWithoutMemoization);

/*
 * `React.cache` performs a shallow comparison of arguments, but our
 * `executeQuery` has complex arguments. This wrapper provides a workaround by
 * turning the function arguments into a string via JSON serialization, enabling
 * a deep equality comparison.
 */
function cacheWithDeepCompare<A extends unknown[], R>(fn: (...args: A) => R): (...args: A) => R {
  const cachedFn = cache((serialized: string) => {
    return fn(...JSON.parse(serialized));
  });
  return (...args: A) => {
    const serialized = JSON.stringify(args);
    return cachedFn(serialized);
  };
}
