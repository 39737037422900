import React, { FC } from "react";

import { Button } from "@/components/ui/button";
import Typography from "@/components/Typography/Typography";
import CustomIconifyIcon from "@/components/CustomIconifyIcon/CustomIconifyIcon";

import {
  ICustomButtonProps,
  IAccentButtonProps,
  ISecondaryButtonProps,
  IOutlinedButtonProps,
} from "@/interfaces/CustomButton/CustomButton";

const CustomButton: FC<ICustomButtonProps> = ({ variant, value, icon, className }) => {
  if (variant === "accent") return <AccentButton value={value} icon={icon} className={className} />;
  else if (variant === "secondary") return <SecondaryButton value={value} />;
  else if (variant === "outline") return <OutlinedButton value={value} />;
  else return <Button>{value}</Button>;
};

const AccentButton: FC<IAccentButtonProps> = ({ value, icon, className }) => {
  return (
    <Button
      className={`bg-colorPrimary hover:bg-colorPrimary-400 active:bg-colorPrimary-600 text-white space-x-[10px] rounded-none h-[52px] whitespace-nowrap py-4 px-6 ${className}`}
    >
      <Typography variant="button-label" className="text-white">
        {value}
      </Typography>
      {icon && <CustomIconifyIcon name={icon} className={"w-5 h-5 text-white"} />}
    </Button>
  );
};

const SecondaryButton: FC<ISecondaryButtonProps> = ({ value }) => {
  return (
    <Button className="bg-white border hover:bg-colorPrimary-100 active:bg-colorPrimary-200 border-colorPrimary rounded-none h-[52px] whitespace-nowrap py-4 px-6">
      <Typography variant="button-label" className="text-colorTertiary">
        {value}
      </Typography>
    </Button>
  );
};

const OutlinedButton: FC<IOutlinedButtonProps> = ({ value }) => {
  return (
    <Button className="bg-white bg-opacity-0 hover:bg-white hover:bg-opacity-30 active:border-opacity-30 border border-white rounded-none h-[52px] whitespace-nowrap py-4 px-6">
      <Typography variant="button-label" className="text-white">
        {value}
      </Typography>
    </Button>
  );
};

export default CustomButton;
