/*
 * The purpose of this module is to manage inside of a Turso database the
 * associations between the GraphQL queries made to the DatoCMS Content Delivery
 * API, and the `Cache-Tags` that these requests return.
 *
 * To store these associations, we use a simple table `query_cache_tags`
 * composed of just two columns:
 *
 * - `query_id` (TEXT): A unique identifier for the query, used to tag the request;
 * - `cache_tag` (TEXT): An actual cache tag returned by the query.
 *
 * These associations will allow us to selectively invalidate individual GraphQL
 * queries, when we receive a "Cache Tags Invalidation" webhook from DatoCMS.
 */
import { Pool } from "@neondatabase/serverless";
import { drizzle } from "drizzle-orm/neon-serverless";
import { inArray } from "drizzle-orm";

import vars from "@/config/vars";

import { tags } from "./schema";
import type { CacheTag } from "./cache-tags";

/*
 * Creates and returns a Turso database client. Note the custom fetch method
 * provided to the Turso client. By setting the `cache` option to `no-store`, we
 * ensure that Next.js does not cache our HTTP requests for database calls.
 */
const database = () => {
  if (vars.Environment === "development") return null;

  const pool = new Pool({
    connectionString: vars.dbConnection,
    ssl: true,
  });

  return drizzle(pool);
};

/*
 * Generates a string of SQL placeholders ('?') separated by commas.
 * It's useful for constructing SQL queries with varying numbers of parameters.
 */
// function sqlPlaceholders(count: number) {
//   return Array.from({ length: count }, () => "?").join(",");
// }

/*
 * Associates DatoCMS Cache Tags to a given GraphQL query. Within an implicit
 * transaction, it initially removes any existing tags for the given queryId,
 * and then adds the new ones. In case of a conflict (e.g. trying to insert a
 * duplicate entry), the operation simply does nothing.
 */
export async function storeQueryCacheTags(queryId: string, cacheTags: CacheTag[]) {
  const db = database();
  if (!db) return null;

  await db.insert(tags).values(
    cacheTags.map((row) => ({
      queryId,
      cacheTag: row,
    })),
  );
}

/*
 * Retrieves the query hashs associated with specified cache tags.
 */
export async function queriesReferencingCacheTags(cacheTags: CacheTag[]): Promise<string[]> {
  const db = database();
  if (!db) return [];

  const result = await db
    .selectDistinct({ queryId: tags.queryId })
    .from(tags)
    .where(inArray(tags.cacheTag, cacheTags));

  return result.map((row) => row.queryId);
}

/*
 * Removes all entries that reference the specified queries.
 */
export async function deleteQueries(queryIds: string[]) {
  const db = database();
  if (!db) return [];

  await db.delete(tags).where(inArray(tags.queryId, queryIds));
}
